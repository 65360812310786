<template>
<div>
  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-card>
      <v-card-title>
        Taxes
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="active=month">{{ "" | nowMonth}}</v-btn>
        <v-btn color="primary" @click="active=prevMonth">{{"" | pastMonth}}</v-btn>
        <v-btn color="primary" @click="active=twoMonth">{{ "" | twoMonths}}</v-btn>
        <v-btn color="primary" @click="active=allPay">All Payments</v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-layout  wrap pl-4>
        Transfer costs: {{totalTCRents}}
      </v-layout>
      <v-data-table :rows-per-page-items='[100,50,25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' :headers="headers" :items="active" :search="search" item-key=".key" :pagination.sync="pagination">
        <template slot="items" slot-scope="props">
          <td><span v-if="props.item.confirmTimestamp!=undefined">{{ props.item.confirmTimestamp | date}}</span><span v-else>{{ props.item.dateSent | date}}</span></td>
          <td>
            <span v-if="props.item.tenantId!=undefined && users[props.item.tenantId]!=undefined">{{ users[props.item.tenantId].userName}}</span>
            <span v-else-if="props.item.landlordId!=undefined && users[props.item.landlordId]!=undefined && users[props.item.landlordId].companyId==undefined" >{{ users[props.item.landlordId].userName}}</span>
            <span v-else-if="props.item.landlordId!=undefined && users[props.item.landlordId]!=undefined && users[props.item.landlordId].companyId!=undefined" >{{ users[props.item.landlordId].userName}} - {{users[props.item.landlordId].companyId}}</span>
          </td>
          <td>{{props.item.tCompany}}</td>
          <td>{{props.item.total}}, {{props.item.margin}}, {{ props.item.amount}} TW$ <span v-if="props.item.price!=undefined">- ({{props.item.price}} TW$)</span>

            <span v-if="props.item.noTax!=undefined">(= {{props.item.noTax}}+{{props.item.tax}} VAT)</span>
          </td>
          <td>{{props.item.comment}}</td>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card>
  </v-container>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Taxes',
  data: () => ({
    active:[],
    pagination: {
      descending: false
    },
    headers: [{
        text: 'Date',
        value: 'ts'
      },
      {
        text: 'User',
        value: 'tName'
      },
      {
        text:'Company?',
        value:"tCompany"
      },
      {
        text: 'Amount',
        value: 'total'
      },
      {
        text: 'Comments',
        value: 'comment'
      },
    ],
    usersLoaded:false,
    search: null,
    searched: [],

  }),
  firebase() {
    return {
      orders: {
        source: firebase.database().ref('completedOrders').orderByChild('confirmTimestamp').startAt(moment().subtract(2,"M").date(1).toISOString()),
        readyCallback: function() {
          let index=[]
          for (var i = 0; i < this.orders.length; i++) {
            if (this.orders[i].status!='Money In' && this.orders[i].status!='Paid'){
              index.unshift(i)
            } else {
              this.orders[i].ts=moment(this.orders[i].confirmTimestamp).valueOf()
              this.orders[i].comment="20% Margin"
            }
          }
          for (var j = 0; j < index.length; j++) {
            this.orders.splice(index[j],1)
          }
          this.active=this.month
          this.matchUsers()
        }
      },
      payments:{
        source: firebase.database().ref('payments').orderByChild('dateSent').startAt(moment().subtract(7,"M").date(1).toISOString()),
        readyCallback: function() {
          for (var i = 0; i < this.payments.length; i++) {
              this.payments[i].ts=moment(this.payments[i].dateSent).valueOf()
              this.payments[i].comment="3% of rent"
            // add logic for the special options like
            this.matchUsers()
          }
        }
      },
      payIn:{
        source:firebase.database().ref('payIn').orderByChild('ts').startAt(moment().subtract(2,"M").date(1).format('YYMMDD')),
        readyCallback: function(){
          console.log('got PayIn');
        }
      },
      users: {
        source: firebase.database().ref('users'),
        asObject: true,
        readyCallback: function() {
          this.usersLoaded=true
          this.matchUsers()
        }

      }
    }
  },

  methods: {
    matchUsers(){
      console.log('do match Users', this.usersLoaded );
      if (this.usersLoaded && this.orders.length>0 && this.payments.length>0){
        for (var i = 0; i < this.orders.length; i++) {
          if (this.users.hasOwnProperty(this.orders[i].tenantId)) {
            this.orders[i].tName=this.users[this.orders[i].tenantId].userName
            this.orders[i].tCompany=false
          } else {

            console.log( 'Tenant not existing for him:',this.orders[i]);
          }
        }
        for (var i = 0; i < this.payments.length; i++) {
          if (this.users.hasOwnProperty(this.payments[i].landlordId)){
            this.payments[i].tName=this.users[this.payments[i].landlordId].userName
            if (this.users[this.payments[i].landlordId].hasOwnProperty('companyId')){
              this.payments[i].tCompany=true
            } else {
              this.payments[i].tCompany=false
            }
          } else {
            console.log('landlord not existing for him:', this.payments[i]);
          }
        }
      }
    },


  },
  filters: {
    dateFormat: function(t) {
      if (t) {
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    month(){
      let br=[]

      br = this.orders.filter(function(el) {
        return moment(el.confirmTimestamp).format('MM')==moment().format('MM')
      });
      for (var i = 0; i < br.length; i++) {
        br[i].amount=br[i].price*.2
      }
      console.log(br, 'this is the BR');
      let rents=[]

      rents = this.payments.filter(function(el) {
        return moment(el.dateSent).format('MM')==moment().format('MM')
      });
      for (var j = 0; j < rents.length; j++) {
        if (rents[j].margin==undefined){
          rents[j].amount=Math.round(rents[j].total/.97*.03)
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
        } else {
          rents[j].amount=rents[j].margin
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
        }
      }

      console.log(rents, "'theseare this month's rents");
      this.active=br.concat(rents)
      return br.concat(rents)
    },
    allPay(){
      let rents=this.payments
      for (var j = 0; j < rents.length; j++) {
        if (rents[j].margin==undefined){
          rents[j].amount=Math.round(rents[j].total/.97*.03)
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
        } else {
          rents[j].amount=rents[j].margin
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
        }
      }
      return rents
    },
    prevMonth(){
      console.log(this.orders[0]);
      let br = this.orders.filter(function(el) {
        return moment(el.confirmTimestamp).format('MM')==moment().subtract(1,"M").format('MM')
      });

      for (var i = 0; i < br.length; i++) {
        br[i].amount=br[i].price*.2
      }
      let rents = this.payments.filter(function(el) {
        return moment(el.dateSent).format('MM')==moment().subtract(1,"M").format('MM')
      });
      for (var j = 0; j < rents.length; j++) {
        if (rents[j].margin==undefined){
          rents[j].amount=Math.round(rents[j].total/.97*.03)
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
        } else {
          rents[j].amount=rents[j].margin
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
        }
      }
      console.log('rents: ',rents);
      return br.concat(rents)
    },
    twoMonth(){
      let br = this.orders.filter(function(el) {
        return moment(el.confirmTimestamp).format('MM')==moment().subtract(2,"M").format('MM')
      })
      for (var i = 0; i < br.length; i++) {
        br[i].amount=br[i].price*.2-br[i].discount
      }
      let rents = this.payments.filter(function(el) {
        return moment(el.dateSent).format('MM')==moment().subtract(2,"M").format('MM')
      });
      for (var j = 0; j < rents.length; j++) {
        if (rents[j].margin==undefined){
          rents[j].amount=Math.round(rents[j].total/.97*.03)
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)

        } else {
          rents[j].amount=rents[j].margin
          rents[j].noTax=Math.round(rents[j].amount/1.05)
          rents[j].tax=Math.round(rents[j].amount*.05/1.05)
      }
      }

      return br.concat(rents)
    },
    totalTCRents(){
      let totalIn=0

      // console.log(this.active[0]);
      for (var i = 0; i < this.payIn.length; i++) {
        // console.log(moment(this.payIn[i].ts,"YYMMDD").format("MM"));
        // console.log(moment(this.active[0].confirmTimestamp).format("MM"));
        // console.log(moment(this.payIn[i].ts,"YYMMDD").isSame(moment(this.active[0].confirmTimestamp,'month')))
        if (this.active[0]!=undefined && this.payIn[i].total!=undefined && moment(this.payIn[i].ts,"YYMMDD").format("MM")==moment(this.active[0].confirmTimestamp).format("MM")) {
          // console.log(this.payIn[i].ts);
          totalIn=totalIn+parseInt(this.payIn[i].total)
        }
        // console.log(totalIn, this.payIn[i].total);
      }
      return Math.round(totalIn/1.028*.028)+ 'TW$ Transaction costs'
    },

  },
  created() {
    this.active=this.allPay
  },
  filters: {
    date(t) {
      return moment(t).format('LL')
    },
    nowMonth(){
      return moment().format("MMM")
    },
    pastMonth(){
      return moment().subtract(1,"M").format("MMM")
    },
    twoMonths(){
      return moment().subtract(2,"M").format("MMM")
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
